import { Fragment } from 'react';
import Main from './Components/MainComponent';
import './style.css';



function App() {
  return (
    <Fragment>
      <Main/>
  </Fragment>
      
   
  );
}

export default App;
