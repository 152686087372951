import React from "react";
import Pastor from "./Pastor";





function Home() {
	return (
		<div className="container p-2">
			<Pastor />
		
		</div>
	)
}

export default Home;